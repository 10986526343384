
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'

interface ITableData {
  id: number,
  name: number,
  intro: string,
  month: string,
  year: string,
  num: number,
  adver: number,
  api: number
}

interface IRechargeForms {
  id: number,
  name: number,
  intro: string,
  month: string,
  year: string,
  num: number,
  adver: number,
  api: number,
  time: number,
  type: string | number,
  payment: string
}

interface IState {
  tableData: ITableData[],
  page: number,
  pageSize: number,
  count: number,
  rechargeForms: IRechargeForms | null,
  rechargeDialog: boolean
}

export default defineComponent({
  name: 'Buy',
  setup () {
    const state = reactive<IState>({
      tableData: [],
      page: 1,
      pageSize: 15,
      count: 0,
      rechargeForms: null,
      rechargeDialog: false
    })
    const router = useRouter()
    const stateAsRefs = toRefs<IState>(state)
    const { proxy }: any = getCurrentInstance()

    const getTableList = (): void => {
      proxy.$axios.get('/item/lists', {
        params: {
          page: state.page,
          page_size: state.pageSize
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.count = res.data.count
          state.tableData = res.data.lists
        }
      })
    }
    getTableList()

    const toPay = (item: ITableData): void => {
      if (item) {
        state.rechargeForms = {
          id: item.id,
          name: item.name,
          intro: item.intro,
          month: item.month,
          year: item.year,
          num: item.num,
          adver: item.adver,
          api: item.api,
          time: 1,
          type: 0,
          payment: '支付宝支付'
        }
      } else {
        ElMessage.error('错误: 表单数据不存在')
        closeRecharge()
      }
      setTimeout(() => {
        state.rechargeDialog = true
      }, 100)
    }

    const pay = (): void => {
      const rechargeForms: IRechargeForms | null = state.rechargeForms
      if (rechargeForms) {
        let price: number = 0
        if (!rechargeForms.type) {
          rechargeForms.type = 'month'
          price = parseFloat(rechargeForms.month) * rechargeForms.time
        } else {
          rechargeForms.type = 'year'
          price = parseFloat(rechargeForms.year) * rechargeForms.time
        }
        let payment: string = ''
        if (rechargeForms.payment === '微信支付') {
          // payment = 'wechat'
          ElMessage.warning({
            message: '暂不支持微信支付',
            type: 'warning'
          })
        } else if (rechargeForms.payment === '支付宝支付') {
          payment = 'alipay'
        } else {
          ElMessage.warning({
            message: '请选择支付类型',
            type: 'warning'
          })
          return
        }
        proxy.$axios.post('/item/order', {
          item_id: rechargeForms.id,
          time: rechargeForms.time,
          type: rechargeForms.type
        }).then((res: IResponse) => {
          closeRecharge()
          setTimeout(() => {
            const routerUrl = router.resolve({ path: '/pay', query: { out_trade_no: res.data.out_trade_no, payment: payment, price: price, name: encodeURIComponent(rechargeForms.name) } })
            location.href = routerUrl.href
          }, 100)
        })
      } else {
        ElMessage.error('表单数据不存在')
        closeRecharge()
      }
    }

    const closeRecharge = (): void => {
      state.rechargeDialog = false
      setTimeout(() => {
        state.rechargeForms = null
      }, 500)
    }

    return {
      ...stateAsRefs,
      toPay,
      pay,
      closeRecharge,
      getTableList
    }
  }
})
