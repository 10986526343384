<template>
  <el-row :gutter="14">
    <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(item, index) in tableData" :key="index">
      <el-card shadow="hover">
        <template #header>
          <div class="card-item__title">
            {{ item.name }}
          </div>
        </template>
        <div class="card-item__coin">
          <div class="card-item__month">
            {{ item.month }}
          </div>
          <div class="card-item__year">
            {{ item.year }}
          </div>
          <el-alert class="card-item__alert" :closable="false" type="error" effect="dark" :center="true">
            <template #title>
              <div>
                一年优惠{{ Math.round((item.year / (item. month * 12)) * 100) }}%
              </div>
            </template>
          </el-alert>
        </div>
        <div class="card-item__intro">
          {{ item.intro }}
        </div>
        <div class="card-item__details">
          <div class="card-item support">
            <em :class="Number(item.adver) ? 'el-icon-success' : 'el-icon-error'"></em>{{ Number(item.adver) ? '无' : '有' }}广告
          </div>
          <div class="card-item support">
            <em :class="Number(item.api) ? 'el-icon-error' : 'el-icon-success'"></em>API支持
          </div>
          <div class="card-item support">
            <em class="el-icon-success"></em>数据分析
          </div>
          <!-- <div class="card-item support">
            <em class="el-icon-success"></em>{{ item.intro }}
          </div> -->
        </div>
        <el-button-group class="card-item__button-group">
          <el-button type="primary" @click="toPay(item)">充值</el-button>
          <!-- <el-button type="primary">一个月</el-button>
          <el-button type="danger">一年</el-button> -->
        </el-button-group>
      </el-card>
    </el-col>
  </el-row>
  <!-- Dialog弹出层 -->
  <!-- 充值对话框 -->
  <el-dialog
    append-to-body
    title="充值"
    destroy-on-close
    :close-on-click-modal="false"
    :width="280"
    top="30vh"
    @close="closeRecharge"
    v-model="rechargeDialog"
  >
    <el-form ref="rechargeForm" status-icon :model="rechargeForms">
      <el-form-item label="充值名称">
        <h2>{{ rechargeForms.name }}</h2>
      </el-form-item>
      <el-form-item>
        <el-switch
          v-model="rechargeForms.type"
          active-color="#409EFF"
          inactive-color="#409EFF"
          active-text="按年付费"
          inactive-text="按月付费">
        </el-switch>
      </el-form-item>
      <el-radio-group v-model="rechargeForms.payment" size="medium">
        <el-radio-button label="支付宝支付"></el-radio-button>
        <el-radio-button disabled label="微信支付"></el-radio-button>
      </el-radio-group>
      <el-form-item label="购买时长">
        <el-input v-model="rechargeForms.time">
          <template #append>
            {{ rechargeForms.type ? '年' : '月' }}
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="text" @click="rechargeDialog = false">关 闭</el-button>
        <el-button type="primary" @click="pay">跳转支付</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'

interface ITableData {
  id: number,
  name: number,
  intro: string,
  month: string,
  year: string,
  num: number,
  adver: number,
  api: number
}

interface IRechargeForms {
  id: number,
  name: number,
  intro: string,
  month: string,
  year: string,
  num: number,
  adver: number,
  api: number,
  time: number,
  type: string | number,
  payment: string
}

interface IState {
  tableData: ITableData[],
  page: number,
  pageSize: number,
  count: number,
  rechargeForms: IRechargeForms | null,
  rechargeDialog: boolean
}

export default defineComponent({
  name: 'Buy',
  setup () {
    const state = reactive<IState>({
      tableData: [],
      page: 1,
      pageSize: 15,
      count: 0,
      rechargeForms: null,
      rechargeDialog: false
    })
    const router = useRouter()
    const stateAsRefs = toRefs<IState>(state)
    const { proxy }: any = getCurrentInstance()

    const getTableList = (): void => {
      proxy.$axios.get('/item/lists', {
        params: {
          page: state.page,
          page_size: state.pageSize
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.count = res.data.count
          state.tableData = res.data.lists
        }
      })
    }
    getTableList()

    const toPay = (item: ITableData): void => {
      if (item) {
        state.rechargeForms = {
          id: item.id,
          name: item.name,
          intro: item.intro,
          month: item.month,
          year: item.year,
          num: item.num,
          adver: item.adver,
          api: item.api,
          time: 1,
          type: 0,
          payment: '支付宝支付'
        }
      } else {
        ElMessage.error('错误: 表单数据不存在')
        closeRecharge()
      }
      setTimeout(() => {
        state.rechargeDialog = true
      }, 100)
    }

    const pay = (): void => {
      const rechargeForms: IRechargeForms | null = state.rechargeForms
      if (rechargeForms) {
        let price: number = 0
        if (!rechargeForms.type) {
          rechargeForms.type = 'month'
          price = parseFloat(rechargeForms.month) * rechargeForms.time
        } else {
          rechargeForms.type = 'year'
          price = parseFloat(rechargeForms.year) * rechargeForms.time
        }
        let payment: string = ''
        if (rechargeForms.payment === '微信支付') {
          // payment = 'wechat'
          ElMessage.warning({
            message: '暂不支持微信支付',
            type: 'warning'
          })
        } else if (rechargeForms.payment === '支付宝支付') {
          payment = 'alipay'
        } else {
          ElMessage.warning({
            message: '请选择支付类型',
            type: 'warning'
          })
          return
        }
        proxy.$axios.post('/item/order', {
          item_id: rechargeForms.id,
          time: rechargeForms.time,
          type: rechargeForms.type
        }).then((res: IResponse) => {
          closeRecharge()
          setTimeout(() => {
            const routerUrl = router.resolve({ path: '/pay', query: { out_trade_no: res.data.out_trade_no, payment: payment, price: price, name: encodeURIComponent(rechargeForms.name) } })
            location.href = routerUrl.href
          }, 100)
        })
      } else {
        ElMessage.error('表单数据不存在')
        closeRecharge()
      }
    }

    const closeRecharge = (): void => {
      state.rechargeDialog = false
      setTimeout(() => {
        state.rechargeForms = null
      }, 500)
    }

    return {
      ...stateAsRefs,
      toPay,
      pay,
      closeRecharge,
      getTableList
    }
  }
})
</script>

<style lang="scss" scoped>
  :deep(.el-card) {
    text-align: center;
  }
  .el-col {
    margin-bottom: 12px;
  }
  .support.card-item {
    font-size: 16px;
    line-height: 1.8;
  }
  :deep(.el-card__body) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 320px;
  }
  .card-item {
    &__title {
      color: #606266;
      font-weight: 700;
      font-size: 20px;
    }
    &__coin {
      .card-item__month {
        color: #606266;
        font-size: 18px;
        font-weight: 500;
        &::before {
          content: '¥';
        }
        &::after {
          content: ' / 30天';
        }
      }
      .card-item__year {
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 10px;
        &::before {
          content: '¥';
        }
        &::after {
          content: ' / 1年';
        }
      }
    }
    &__intro {
      padding: 4px 8px;
      background: #E6A23C;
      color: #FFF;
      font-size: 14px;
      margin: 12px 0;
      border-radius: 4px;
      box-shadow: inset 0 2px 2px rgba(0, 0, 0, .12);
      &:hover {
        box-shadow: 0 2px 2px rgba(0, 0, 0, .12);
      }
    }
    &__alert {
      width: 130px;
      margin: 0 auto;
    }
    &__button-group {
      width: 100%;
      margin-top: 16px;
      .el-button {
        width: 100%;
        display: block;
      }
    }
    &__details {
      text-align: left;
      display: inline-block;
      .support {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 1.5;
        font-family: "Nunito", "Segoe UI", arial;
        color: #6c757d;
        &:last-child {
          margin-bottom: 0;
        }
      }
      em {
        font-size: 18px;
        margin-right: 10px;
      }
    }
    .el-icon-error {
      color: #F56C6C;
    }
    .el-icon-success {
      color: #67C23A;
    }
  }
  @media screen and (max-width: 500px) {
    .el-col {
      max-width: 100%;
      flex: 100%;
    }
  }
</style>
